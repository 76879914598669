<template>
  <div class="container">
    <div class="bar">
      <span>患者随访数据统计平台</span>
    </div>
    <div class="loginbackground">
      <div class="loginbox">
        <span>登录随访数据统计平台</span>
        <el-form
          class="login_form"
          :model="loginForm"
          :rules="loginFormRules"
          ref="loginFromRef"
        >
          <el-form-item prop="accountNumber">
            <span slot="label">
              <span class="custlabel">账号</span>
            </span>
            <el-input
              v-model.trim="loginForm.accountNumber"
              :class="{ focusstyle: focusUser }"
              clearable
              placeholder="请输入账号"
              @blur="focusUser = false"
              @focus="focusUser = true"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <span slot="label">
              <span class="custlabel">密码</span>
            </span>
            <el-input
              v-model.trim="loginForm.password"
              :class="{ focusstyle: focusPass }"
              autocomplete="off"
              clearable
              placeholder="请输入密码"
              type="password"
              @blur="focusPass = false"
              @focus="focusPass = true"
            ></el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button
              type="primary"
              @click="login"
              @keyup.enter.native="loginEnter"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('密码不能为空'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        accountNumber: '',
        password: '',
      },
      focusUser: false,
      focusPass: false,
      loading: false,

      //表单验证规则对象
      loginFormRules: {
        //验证用户名
        accountNumber: [{ validator: checkUserName, trigger: 'blur' }],
        //验证密码
        password: [{ validator: validatePass, trigger: 'blur' }],
      },
    };
  },
  created() {
    document.onkeydown = e => {
      let key;
      // eslint-disable-next-line no-undefined
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        this.login();
      }
    };
  },
  methods: {
    //登录事件
    login() {
      this.$refs.loginFromRef.validate(valid => {
        if (!valid) {
          console.log('未填写完整');
          return this.$message.error('请填写完整！');
        }
        this.$store.dispatch('loginAbout/login', this.loginForm);
      });
    },
  },
  beforeDestroy() {
    //离开页面阻止回车事件
    // eslint-disable-next-line no-undefined
    document.onkeydown = undefined;
  },
  destoryed() {
    //离开页面阻止回车事件
    // eslint-disable-next-line no-undefined
    document.onkeydown = undefined;
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-color: skyblue;
  box-sizing: border-box;
  font-family: PingFangSC-Medium, PingFang SC;
  min-width: 1500px;
  overflow: hidden;
  .bar {
    height: 6%;
    background-color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    span {
      margin-left: 109px;
    }
  }
  .loginbackground {
    height: 94%;
    background-color: #2c3e50;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-image: url('../assets/images/loginbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    .loginbox {
      width: 480px;
      height: 516px;
      background: #ffffff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      min-height: 510px;
      box-sizing: border-box;
      margin-right: 250px;
      span {
        font-weight: 500;
        color: #111111;
        font-size: 34px;
        margin-top: 64px;
      }
      /deep/ .el-form {
        margin-top: 64px;
        .el-form-item {
          margin-bottom: 24px;
          .btn {
            padding: 0;
          }
        }

        .el-input__prefix {
          color: #76acf2;
        }

        .el-input__inner {
          box-sizing: border-box;
          border: 1px solid #dcdee0;
          width: 325px;
          height: 47px;
          font-size: 18px;
          border-radius: 2px;
          background-color: rgba(255, 255, 255, 1);
          //box-shadow: 0px 0px 14px 4px rgba(201, 229, 247, 0.3);
        }

        //input:-internal-autofill-selected {
        //  // background-color:none!important;
        //}

        input::placeholder {
          color: #c8c9cc;
        }

        .focusstyle {
          .el-input__prefix {
            color: #2c89dc;
          }

          .el-input__inner {
            border: 1px solid #155bd4;
            box-shadow: 0px 0px 4px 0px rgba(10, 42, 97, 0.2);
          }
        }

        .el-form-item__content {
          margin-left: 0 !important;
          font-size: 24px !important;
        }

        .el-form-item__error {
          left: 30px;
        }

        .el-checkbox {
          color: #a1a1a1;
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #c8c9cc;
          // font-size: 16px;
          font-size: 24px;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #2c89dc;
          border-color: #2c89dc;
          border-radius: 5px;
        }

        .el-button {
          margin-top: 20px;
          color: #fff;
          font-size: 26px;
          width: 200px;
          height: 48px;
          background: #155bd4;
          border-radius: 2px;
        }
      }
    }
  }
}
.custlabel {
  font-size: 24px !important;
  color: #333333 !important;
  font-weight: 400 !important;
  line-height: 33px !important;
}
</style>
